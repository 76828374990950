<template>
  <v-app>
    <SiteNavigationLeftDrawer />
    <v-main class="color-background">
      <slot />
    </v-main>
    <SiteBottomNavigation v-if="smAndDown" />
  </v-app>
</template>
<script setup>
import { useDisplay } from "vuetify";
const { smAndDown } = useDisplay();
</script>
<style scoped>
.color-background {
  background-color: rgb(var(--v-theme-background));
}
</style>
